import { FC, useEffect, useRef } from 'react';
import { defaultFnType } from 'types';

const INPUT_LIMIT = 6;

type InputProps = {
  className?: string;
  isDisabled?: boolean;
  regex?: RegExp;
  setOtp: (otp: string) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onEnter?: defaultFnType;
  autoFocus?: boolean;
  customStyles?: string;
  wrapperClassName?: string;
  value: string;
};

const OtpInput: FC<InputProps> = ({
  value,
  className,
  setOtp,
  onBlur,
  onEnter,
  isDisabled = false,
  regex,
  autoFocus = true,
  customStyles = null,
  wrapperClassName = null,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    const input = (event.target as HTMLInputElement).value.slice(0, 1);

    if (regex && !input.match(regex)) return;

    const newOtp = value.split('');

    newOtp[index] = input;

    setOtp(newOtp.join(''));

    if (input && index < INPUT_LIMIT - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key } = event;

    if (key === 'Backspace') {
      event.preventDefault();

      const newOtp = value.split('');

      if (value[index]) {
        newOtp[index] = '';
      } else if (index > 0) {
        newOtp[index - 1] = '';
        inputRefs.current[index - 1]?.focus();
      }

      setOtp(newOtp.join(''));
    } else if (key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (key === 'ArrowRight' && index < INPUT_LIMIT - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (key === 'Enter' && onEnter) {
      onEnter();
    } else if (key === 'ArrowUp') {
      inputRefs.current[0]?.focus();
    } else if (key === 'ArrowDown') {
      inputRefs.current[INPUT_LIMIT - 1]?.focus();
    }
  };

  const getClipboardContent = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault();

    const pastedValue = event.clipboardData.getData('text/plain').trim();

    if (!pastedValue || (regex && !pastedValue.match(regex))) return;

    const newOtp = value.split('');
    let nextIndex = index;

    for (let i = 0; i < pastedValue.length && nextIndex < INPUT_LIMIT; i++) {
      const char = pastedValue[i];

      if (regex && !char.match(regex)) continue;
      newOtp[nextIndex] = char;
      nextIndex++;
    }

    setOtp(newOtp.join(''));

    if (nextIndex < INPUT_LIMIT) {
      inputRefs.current[nextIndex]?.focus();
    } else {
      inputRefs.current[INPUT_LIMIT - 1]?.focus();
    }
  };

  useEffect(() => {
    if (autoFocus && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [autoFocus]);

  return (
    <form>
      <div className={`tw-flex ${wrapperClassName ?? 'tw-gap-2'}`}>
        {new Array(INPUT_LIMIT).fill('').map((_, index) => (
          <input
            key={index}
            type='text'
            id={'otp' + index}
            inputMode='numeric'
            pattern='[0-9]*'
            className={`${
              customStyles ?? 'tw-w-[45px] tw-h-[45px] md:tw-w-[58px] md:tw-h-[58px]'
            } tw-text-TEXT_GRAY_1 tw-text-center tw-rounded tw-border tw-border-DIVIDER_GRAY tw-disabled:opacity-60
             tw-bg-BASE_SECONDARY ${className}`}
            maxLength={1}
            value={value[index] || ''}
            disabled={isDisabled}
            onBlur={onBlur}
            onInput={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={(e) => getClipboardContent(e, index)}
            ref={(el) => {
              inputRefs.current[index] = el;
            }}
            autoComplete='one-time-code'
          />
        ))}
      </div>
    </form>
  );
};

export default OtpInput;
