import { FC, memo } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_FLAGS } from 'constants/config';
import mixpanelEvents from 'constants/mixpanel';
import { ROUTES_PATH } from 'constants/routeConfig';
import { Label } from 'destiny/dist/components/molecules/label';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import { useLogout } from 'hooks/useLogout';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { defaultFnType } from 'types';
import { isDemoMerchant, isIndividualMerchant } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import Avatar from 'components/avatar/Avatar';
import AccessWrapper from 'components/wrappers/AccessWrapper';

export interface SidebarProfileSectionOtcProps {
  userName: string;
  dropdownState: boolean;
  toggleDropdown: defaultFnType;
  merchantName: string;
}

const SidebarProfileSection: FC<SidebarProfileSectionOtcProps> = ({
  userName,
  dropdownState,
  toggleDropdown,
  merchantName,
}) => {
  const router = useRouter();
  const doLogout = useLogout();

  const handleLogout = async () => {
    try {
      doLogout();
      trackMixpanel(mixpanelEvents.LOGOUT_BUTTON_CLICK, {});
      trackMixpanel(mixpanelEvents.RESET, {});
    } catch (e) {
      console.log(e);
    }
  };

  const { checkConfigEnabled } = useConfiguration();
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isPayoutsEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_PAYOUTS_ENABLED];
  const isOtcEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_OTC_ENABLED];
  const isIndividual = isIndividualMerchant();

  const isPoliciesEnabled = checkConfigEnabled(CONFIG_DEFINITIONS.POLICIES_ENABLED);
  const isDemoAccount = isDemoMerchant();

  const hideIndividual = isIndividual || isDemoAccount;

  return (
    <div className='tw-pointer-events-none tw-transition-all tw-select-none tw-duration-300 tw-absolute tw-w-[200px] tw-bottom-0 tw-mx-1.5 tw-mb-2'>
      <div
        className={`tw-ease-out tw-bg-white tw-duration-300 lavander_1-box-shadow tw-rounded-lg tw-overflow-hidden f-14-300 ${
          dropdownState ? 'tw-pointer-events-auto tw-mb-2 tw-h-auto' : 'tw-pointer-events-none tw-h-0'
        }`}
      >
        {!hideIndividual && (
          <AccessWrapper
            permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.permissionId}
            scope={FLOW_PERMISSION_PROPERTIES.MANAGE_COMPANIES.scope}
            hideDeniedComponent
          >
            <div
              className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
              role='button'
              tabIndex={0}
              onClick={() => router.push(ROUTES_PATH.COMPANY_DETAILS)}
            >
              <SvgSpriteLoader id='building-05' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={20} height={20} />
              <div className='tw-ml-[18px]'>Company Details</div>
            </div>
          </AccessWrapper>
        )}

        {!hideIndividual && (
          <AccessWrapper
            permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.permissionId}
            scope={FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope}
            hideDeniedComponent
          >
            <div
              className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
              role='button'
              tabIndex={0}
              onClick={() => router.push(ROUTES_PATH.TEAM)}
            >
              <SvgSpriteLoader id='users-01' iconCategory={ICON_SPRITE_TYPES.USERS} width={20} height={20} />
              <div className='tw-ml-[18px]'>Team</div>
            </div>
          </AccessWrapper>
        )}
        {!hideIndividual && isPoliciesEnabled && isPayoutsEnabled && (
          <AccessWrapper
            permissionId={FLOW_PERMISSION_PROPERTIES.MANAGE_POLICIES.permissionId}
            scope={FLOW_PERMISSION_PROPERTIES.MANAGE_POLICIES.scope}
            hideDeniedComponent
          >
            <div
              className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
              role='button'
              tabIndex={0}
              onClick={() => router.push(ROUTES_PATH.POLICIES)}
            >
              <SvgSpriteLoader id='shield-tick' iconCategory={ICON_SPRITE_TYPES.SECURITY} width={20} height={20} />
              <div className='tw-ml-[18px]'>Payment Policies</div>
            </div>
          </AccessWrapper>
        )}
        {(isPayoutsEnabled || isOtcEnabled) && (
          <div
            className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
            role='button'
            tabIndex={0}
            onClick={() => router.push(ROUTES_PATH.SETTINGS)}
          >
            <SvgSpriteLoader id='settings-01' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={20} height={20} />
            <div className='tw-ml-[18px]'>Settings</div>
          </div>
        )}
        <div
          className='tw-relative tw-min-h-[56px] tw-flex tw-justify-start tw-py-4 tw-px-6 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out  tw-items-center'
          role='button'
          tabIndex={0}
          onClick={handleLogout}
        >
          <SvgSpriteLoader
            id='log-out-02'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={20}
            height={20}
            color={COLORS.RED_PRIMARY}
          />
          <div className='tw-ml-[18px] tw-text-ERROR_RED'>Logout</div>
        </div>
      </div>
      <div
        className='tw-pointer-events-auto tw-overflow-hidden !tw-min-h-[68px] tw-bg-BASE_SECONDARY tw-transition-all tw-duration-300 tw-relative tw-border tw-border-GRAY_1 tw-items-center tw-flex tw-rounded-lg tw-p-3 tw-w-full tw-justify-between'
        tabIndex={0}
        role='button'
        onClick={toggleDropdown}
      >
        <div className='tw-flex tw-items-center'>
          <div className='!tw-min-w-10 !tw-min-h-10 tw-z-50'>
            <Avatar name={userName ?? ''} className='!tw-w-10 !tw-h-10' />
          </div>

          <Label
            title={userName}
            description={merchantName}
            titleClass='f-14-400 tw-mb-0.5'
            descriptionClass='f-14-300 tw-text-TEXT_SECONDARY'
            wrapperClass='tw-ml-2'
            id='SIDEBAR_PROFILE_LABEL'
          />
        </div>
        <SvgSpriteLoader
          id='chevron-down'
          iconCategory={ICON_SPRITE_TYPES.ARROWS}
          width={20}
          height={20}
          className={`tw-transition-all tw-duration-300 ${dropdownState ? ' tw-rotate-180' : ''}`}
        />
      </div>
    </div>
  );
};

export default memo(SidebarProfileSection);
