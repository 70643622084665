import { ACCESS_FLAGS } from 'constants/config';
import mixpanelEvents from 'constants/mixpanel';
import { MenuItem, PRODUCT_GROUPS, PRODUCT_TYPES } from 'types';

export enum PaymentType {
  CRYPTO = 'CRYPTO',
  TREASURY = 'Treasury',
  FIAT = 'FIAT',
  BULK = 'BULK',
}

export enum APITags {
  GET_USER = 'GET_USER',
  GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS',
  GET_BENEFICIARY = 'GET_BENEFICIARY',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_TRANSACTIONS_V2 = 'GET_TRANSACTIONS_V2',
  GET_BULK_TRANSACTIONS = 'GET_BULK_TRANSACTIONS',
  GET_TEAMS = 'GET_TEAMS',
  GET_PENDING_TXN_COUNT = 'GET_PENDING_TXN_COUNT',
  GET_PENDING_TXNS = 'GET_PENDING_TXNS',
  GET_PAYMENT_REQUESTS = 'GET_PAYMENT_REQUESTS',
  GET_ALL_CARDS = 'GET_ALL_CARDS',
  GET_CARD_BY_ID = 'GET_CARD_BY_ID',
  GET_CONSENT_STATUS = 'GET_CONSENT_STATUS',
  GET_VAULTS_BY_TYPE = 'GET_VAULTS_BY_TYPE',
  GET_INTERNAL_TRANSFER_VAULTS = 'GET_INTERNAL_TRANSFER_VAULTS',
  GET_INTERNAL_TRANSFER_ACCOUNTS = 'GET_INTERNAL_TRANSFER_ACCOUNTS',
  GET_MERCHANT_DETAILS = 'GET_MERCHANT_DETAILS',
  GET_CONNECTED_ERP_LIST = 'GET_CONNECTED_ERP_LIST',
  GET_PENDING_ERP_LIST = 'GET_PENDING_ERP_LIST',
  GET_ERP_MAPPING = 'GET_ERP_MAPPING',
  GET_TREASURY_ASSET_WATCHLIST = 'GET_TREASURY_ASSET_WATCHLIST',
  GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS',
  GET_PROMPT = 'GET_PROMPT',
  GET_KYB = 'GET_KYB',
  GET_NAVIGATION = 'GET_NAVIGATION',
  GET_SHAREABLE_FORM = 'GET_SHAREABLE_FORM',
  GET_ALL_ENTITIES = 'GET_ALL_ENTITIES',
  GET_PENDING_ACCOUNTS = 'GET_PENDING_ACCOUNTS',
  GET_POLICIES = 'GET_POLICIES',
  GET_PENDING_TRANSACTIONS = 'GET_PENDING_TRANSACTIONS',
  GET_PENDING_TRANSACTION_DETAILS = 'GET_PENDING_TRANSACTION_DETAILS',
  GET_PENDING_WALLETS = 'GET_PENDING_WALLETS',
  GET_VERIFIED_DEPOSITORS = 'GET_VERIFIED_DEPOSITORS',
  GET_WHITELISTED_ADDRESSES_BY_VERIFIED_DEPOSITOR_ID = 'GET_WHITELISTED_ADDRESSES_BY_VERIFIED_DEPOSITOR_ID',
  GET_COUNTER_PARTY_BY_ID = 'GET_COUNTER_PARTY_BY_ID',
  GET_UNVERIFIED_ENTITIES = 'GET_UNVERIFIED_ENTITIES',
  GET_WHITELISTED_RESOURCES = 'GET_WHITELISTED_RESOURCES',
  GET_RFI_BY_KYC_ID = 'GET_RFI_BY_KYC_ID',
}

export const API_TAGS = Object.values(APITags);

export const MOVE_MONEY_OPTIONS: MenuItem[] = [
  {
    label: 'Single Transfer',
    value: 'single-transfer',
    isDisabled: false,
  },
  {
    label: 'Bulk Transfer',
    value: 'bulk-transfer',
    isDisabled: false,
  },
];

export const currencies: MenuItem[] = [
  { label: 'USD', value: 'USD' },
  { label: 'USDC', value: 'USDC' },
];

export const investmentCurrencies: MenuItem[] = [{ label: 'USD', value: 'USD' }];

export enum AppNames {
  SIRIUS = 'sirius',
  ROMA = 'roma',
}

export const sidebarEventsMapTreasury: any = {
  Home: mixpanelEvents.YIELD_HOME_SIDEBAR_CLICK,
  'Investment Planner': mixpanelEvents.YIELD_INVESTMENT_PLANNER_SIDEBAR_CLICK,
  'My Investments': mixpanelEvents.YIELD_MY_INVESTMENTS_SIDEBAR_CLICK,
  Accounts: mixpanelEvents.YIELD_ACCOUNTS_SIDEBAR_CLICK,
  Transactions: mixpanelEvents.YIELD_TRANSACTION_SIDEBAR_CLICK,
};

export const sidebarEventsMapPayouts: any = {
  Home: mixpanelEvents.ROMA_HOME_SIDEBAR_CLICK,
  Transactions: mixpanelEvents.TRANSACTION_SIDEBAR_CLICK,
  Beneficiaries: mixpanelEvents.BENEFICIARY_SIDEBAR_CLICK,
  'Payment Requests': mixpanelEvents.REQUEST_PAYMENT_SIDEBAR_CLICK,
};

export const sidebarEventsMap: any = {
  [ACCESS_FLAGS.IS_PAYOUTS_ENABLED]: sidebarEventsMapPayouts,
  [ACCESS_FLAGS.IS_TREASURY_ENABLED]: sidebarEventsMapTreasury,
};

export enum AccountsListHeadings {
  CURRENCY = 'CURRENCY',
  ACCOUNT_NUMBER = 'ACCOUNT NUMBER',
  BALANCE = 'BALANCE',
  ACTION = 'ACTION',
}

export const enum REQUEST_TYPES {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const SCREEN_BREAKPOINTS = {
  MIN_WIDTH: 854,
  MIN_HEIGHT: 300,
  XL_WIDTH: 1280,
};

export const ERROR_TOKENS = {
  USER_WITH_NO_PERMISSIONS: 'USER_WITH_NO_PERMISSIONS',
  CLIENT_INVALID_API_CALL: 'CLIENT_INVALID_API_CALL',
  NEW_AND_OLD_ACCESS_MISMATCH: 'NEW_AND_OLD_ACCESS_MISMATCH',
  INVALID_TOKEN: 'INVALID_TOKEN',
  MISSING_TOKEN: 'MISSING_TOKEN',
  CLIENT_SIDE_EXCEPTION: 'CLIENT_SIDE_EXCEPTION',
  PAGE_BREAK: 'PAGE_BREAK',
  PAGE_404: 'PAGE_404',
  NO_PERMISSIONS_PAGE: 'NO_PERMISSIONS_PAGE',
  FAILED_TO_INITIATE_LAUNCHDARKLY: 'FAILED_TO_INITIATE_LAUNCHDARKLY',
};

export const SESSION_EXPIRY_TOKENS = [ERROR_TOKENS.INVALID_TOKEN, ERROR_TOKENS.MISSING_TOKEN];

export const CLOUD_BUCKET_URL = 'https://storage.googleapis.com/zamp-prd-sg-merchant-ui/banking_dashboard';

export const SESSION_CAPABILITY_CONTEXT_KEYS = {
  CREATE_CARD_KEY: 'create_card',
  FREEZE_CARD_KEY: 'freeze_card',
  DELETE_CARD_KEY: 'block_card',
  EDIT_CARD_NICKNAME_KEY: 'change_card_nickname',
  CONSENT: 'consent',
  EDIT_VAULT_NAME: 'edit_vault_name',
  UPDATE_USER_ROLE: 'update_user_role',
  ADD_VAULT_ACCOUNT: 'add_vault_account',
  REQUEST_CURRENCY: 'request_currency',
  ADD_VAULT: 'add_vault',
  INTERNAL_TRANSFER: 'internal_transfer',
  CREATE_ENTITY: 'create_entity',
  ACTIVATE_PRODUCT: 'activate_product',
  UPDATE_PRODUCT_INTENT: 'update_product_intent',
  CREATE_CSV: 'create_csv',
  BULK_PAYOUT_CREATE: 'bulk_payout_create',
  BULK_PAYOUT_EXECUTE: 'bulk_payout_execute',
  SHAREABLE_FORM_CREATE: 'shareable_form_create',
  ADD_VAULT_ACCOUNT_V2: 'add_vault_account_v2',
  CREATE_ROLE: 'create_role',
  CREATE_CSV_V2: 'create_csv_v2',
  CREATE_CUSTOM_PAYMENT_LINKS_CSV: 'create_custom_payment_links_csv',
  CREATE_POLICY: 'create_policy',
  REQUEST_PAYOUTS_TRANSACTION: 'request_payouts_transaction',
  APPROVE_PAYOUTS_TRANSACTION: 'approve_payouts_transaction',
  DECLINE_PAYOUTS_TRANSACTION: 'decline_payouts_transaction',
  APPROVE_PAYOUTS_TRANSACTIONS: 'approve_payouts_transactions',
  DECLINE_PAYOUTS_TRANSACTIONS: 'decline_payouts_transactions',
  DELETE_POLICY: 'delete_policy',
  GENERATE_PAYMENT_LINK: 'b2buser_generate_payment_link',
  POST_WIRE_WITHDRAWAL_TRANSACTION: 'post_wire_withdrawal_transaction',
  WIRE_WITHDRAWAL_VALIDATE: 'wire_withdrawal_validate',
  KYB_SUBMIT: 'post_kyb_submission',
  POST_WIRE_WITHDRAWL: 'post_wire_withdrawal',
  DELETE_WHITELISTED_RESOURCE: 'delete_whitelisted_resource',
  WHITELIST_DEPOSITOR_ADDRESS: 'whitelist_depositor_resource',
  CREATE_VERIFIED_DEPOSITOR: 'create_depositor',
  WHITESLIST_CREADIT_ACCOUNT_ADDRESS: 'whitelist_credit_account_address',
  DELETE_DEPOSITOR: 'delete_depositor',
  CREATE_COUNTERPARTY: 'create_counterparty',
  EDIT_COUNTERPARTY: 'update_counterparty',
  DELETE_COUNTERPARTY: 'delete_counterparty',
  UPDATE_SLIPPAGE: 'update_slippage',
  DELETE_ENTITY: 'delete_entity',
  POST_KYC_RFI_SUBMISSION: 'post_kyc__rfi_submission',
};

export const CONSENT_TYPES = {
  PAYMENTS: 'payments',
};

export const T_AND_C_CODE = {
  BUSINESS_BANKING: 'business_banking',
  PAYMENT_CONSENT: 'payment_consent',
  ROMA: 'roma',
  SIRIUS: 'sirius',
  TREASURY: 'treasury',
  BANKING: 'banking',
  ROMA_INDIVIDUAL: 'roma_individual',
};

export enum ORIENTATION_ENUMS {
  VERTICAL,
  HORIZONTAL,
  HORIZONTAL_REVERSE,
}

export const REQUIRED_ERROR = '* Required';

export const PRODUCT_GROUP_KYB_MAPPING = {
  [PRODUCT_TYPES.banking_account]: PRODUCT_GROUPS.banking,
  [PRODUCT_TYPES.treasury_account]: PRODUCT_GROUPS.treasury,
  [PRODUCT_TYPES.cards]: PRODUCT_GROUPS.cards,
  [PRODUCT_TYPES.USD_BANKING]: PRODUCT_GROUPS.banking,
  [PRODUCT_TYPES.CRYPTO_PAYMENT_GATEWAY]: PRODUCT_GROUPS.banking,
  [PRODUCT_TYPES.OTC_DESK]: PRODUCT_GROUPS.banking,
};

export const DATA_ROW_STYLE = ' tw-rounded-2.5 f-14-300 ';
export const DATA_ITEM_STYLE = 'tw-py-6 tw-px-3';
export const TABLE_HEADER_STYLE = 'tw-sticky tw-top-0 tw-bg-white tw-text-TEXT_SECONDARY f-10-600';
export const TABLE_HEADER_ITEM_STYLE =
  'tw-border-b tw-border-DIVIDER_GRAY tw-px-3 tw-py-3  tw-font-semibold tw-text-left';
export const FLEXI_TABLE = 'tw-w-full tw-table-auto tw-border-separate tw-border-spacing-0';

export const FILE_SIZE = {
  ONE_MB: 1048576,
  TWO_MB: 20971520,
  THREE_MB: 3145728,
};

export enum ERROR_CODE {
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  INVITATION_ALREADY_EXISTS = 'INVITATION_ALREADY_EXISTS',
  USER_ALREADY_ADDED_AND_DELETED = 'USER_ALREADY_ADDED_AND_DELETED',
  AUTH_LAYER_FAILURE = 'AUTH_LAYER_FAILURE',
}

export const RTK_ABORT_ERROR_NAME = 'AbortError';

export const MASKED_DOTS = '• • ';

export enum BUSINESS_PRODUCT_TYPES {
  CASH_OPS = 'CASH_OPS',
  TREASURY = 'TREASURY',
  ROMA = 'ROMA',
}

export const SIGN_IN_PAGE_INVESTMENT_TITLE = 'Get started in 10 mins';
export const SIGN_IN_PAGE_TITLE_ROMA = 'The Future of Financial Infrastructure';
export const SIGN_IN_PAGE_TITLE = 'Global business banking. Simplified';
export const CANARY_HEADER_KEY = 'X-Canary';
export const OTC = 'otc';

export const HIDE_USD_BANKING_MERCHANTS = [
  '34d22d_oK4LoqQXNVnHJr5zmmk9Z3_10_24',
  'x5119f_6xXwbABvYecdzbY8YLnNui_11_28',
  'rs36ah_LmFrPWC2Hqmv4g5cT2vZMJ_11_28',
  'rocake_taVgaXuoQPmvPEuKyr8jvV_11_28',
  '632n33_tYDCn5dYUZuHW7BxNJj5zQ_11_29',
];

export enum SIGNUP_TYPE {
  INDIVIDUAL = 'Individual',
  CORPORATE = 'Corporate',
}

export const SIGNUP_RADIO_LIST = [
  { label: SIGNUP_TYPE.INDIVIDUAL, value: SIGNUP_TYPE.INDIVIDUAL },
  { label: SIGNUP_TYPE.CORPORATE, value: SIGNUP_TYPE.CORPORATE },
];
