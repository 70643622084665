import { useState } from 'react';
import { ACCESS_FLAGS } from 'constants/config';
import { ROMA_LOGO, ROMA_LOGO_GREEN, ZAMP_LOGO } from 'constants/icons';
import { OTC } from 'constants/index';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import mixpanelEvents from 'constants/mixpanel';
import { ROUTES_PATH } from 'constants/routeConfig';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { useLogout } from 'hooks/useLogout';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MapAny } from 'types';
import { NavigationAdditionalPropertiesSchema, NavigationItemSchema } from 'types/config';
import { isDemoMerchant, isIndividualMerchant, isRomaDashboard } from 'utils/common';
import { getVariation } from 'utils/launchDarkly';
import { trackMixpanel } from 'utils/mixpanel';
import SidebarTabWrapper from 'components/layout/SidebarTabWrapper';
import MoveMoney from 'components/move-money';

interface DashboardTopbarProps {
  config?: NavigationAdditionalPropertiesSchema;
  navigation?: MapAny;
  userAccessFlags: MapAny;
}

const DashboardTopbar: React.FC<DashboardTopbarProps> = ({
  config = {
    is_move_money: false,
    is_contact_us: true,
    is_help: false,
    is_notifications: false,
    is_faq: false,
  },
  navigation,
  userAccessFlags,
}) => {
  const router = useRouter();

  const isRoma = isRomaDashboard();
  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);
  const isDemoAccount = isDemoMerchant();
  const isIndividual = isIndividualMerchant();
  const [showDashboardItems, setShowDashboardItems] = useState(false);

  const accessFlag = userAccessFlags[ACCESS_FLAGS.IS_TREASURY_ENABLED]
    ? ACCESS_FLAGS.IS_TREASURY_ENABLED
    : ACCESS_FLAGS.IS_PAYOUTS_ENABLED;

  const hideIndividual = isIndividual || isDemoAccount;

  const doLogout = useLogout();

  const handleLogout = async () => {
    try {
      doLogout();
      trackMixpanel(mixpanelEvents.LOGOUT_BUTTON_CLICK, {});
      trackMixpanel(mixpanelEvents.RESET, {});
    } catch (e) {
      console.log(e);
    }
  };

  const toggleDashboardItems = () => setShowDashboardItems(!showDashboardItems);

  return (
    <div className='tw-relative'>
      <div
        className={`tw-sticky tw-top-0 tw-w-full tw-flex tw-items-center tw-z-50 tw-border tw-border-DIVIDER_GRAY md:tw-border-none ${
          isOtcEnabled
            ? 'tw-py-5 md:tw-py-2 tw-pr-5 tw-pl-5 md:tw-pr-10 tw-bg-white'
            : 'tw-h-topbar tw-pl-[50px] tw-pr-[70px] tw-bg-BASE_SECONDARY'
        }`}
        data-testid='topbar'
      >
        <Link href={ROUTES_PATH.HOME}>
          {isOtcEnabled ? (
            <Image
              width={66}
              height={16}
              alt={'roma logo'}
              className='tw-w-[66px] tw-align-middle'
              src={ROMA_LOGO_GREEN}
              priority={true}
            />
          ) : (
            <Image
              width={92}
              height={22}
              alt={'zamp logo'}
              className='tw-w-[92px] tw-align-middle'
              src={isRoma ? ROMA_LOGO : ZAMP_LOGO}
              priority={true}
            />
          )}
        </Link>
        <div className='tw-flex-grow'></div>
        {config.is_move_money && !router.pathname?.includes(OTC) && !hideIndividual && <MoveMoney />}
        {config.is_help && (
          <Link href={ROUTES_PATH.HELP} onClick={() => trackMixpanel(mixpanelEvents.HELP_CLICK, {})}>
            <span
              className={`tw-text-sm tw-ml-6 ${
                router.asPath == ROUTES_PATH.HELP ? 'tw-text-ZAMP_PRIMARY tw-font-medium' : 'tw-text-TEXT_GRAY_1'
              }`}
            >
              Help
            </span>
          </Link>
        )}
        {config.is_contact_us && (
          <Link href={ROUTES_PATH.CONTACT_US}>
            <span
              className={`tw-text-sm tw-ml-6 ${
                router.asPath == ROUTES_PATH.CONTACT_US ? 'tw-text-ZAMP_PRIMARY tw-font-medium' : 'tw-text-TEXT_GRAY_1'
              }`}
            >
              Contact us
            </span>
          </Link>
        )}
        {config.is_faq && (
          <Link href={ROUTES_PATH.FAQ}>
            <span
              className={`tw-text-sm tw-ml-6 ${
                router.asPath == ROUTES_PATH.FAQ ? 'tw-text-ZAMP_PRIMARY tw-font-medium' : 'tw-text-TEXT_GRAY_1'
              }`}
            >
              FAQs
            </span>
          </Link>
        )}
        <div className='tw-cursor-pointer md:tw-hidden' onClick={toggleDashboardItems}>
          {showDashboardItems ? (
            <SvgSpriteLoader id='x-close' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={24} height={24} />
          ) : (
            <SvgSpriteLoader id='menu-01' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={24} height={24} />
          )}
        </div>
      </div>

      <div
        className={`tw-ease-in-out tw-duration-500 tw-absolute tw-z-[999] tw-top-full tw-rounded-b-lg tw-bg-white tw-flex tw-flex-col tw-w-full ${
          showDashboardItems ? 'tw-h-[calc(100vh-60px)]' : 'tw-h-0'
        } tw-overflow-hidden`}
      >
        {navigation &&
          navigation[0]?.nav_items.map((route: NavigationItemSchema) => (
            <Link href={route.route} key={route.key}>
              <SidebarTabWrapper
                name={route.display_name}
                iconId={route.iconId}
                iconCategory={route.iconCategory}
                isSelected={router.pathname === route.route}
                accessFlag={accessFlag}
                onClick={() => setShowDashboardItems(false)}
                expanded
              />
            </Link>
          ))}

        <div className='tw-mt-auto'>
          <div
            className='tw-min-h-[56px] tw-bg-[#FBFBFB] tw-border tw-border-DIVIDER_GRAY tw-flex tw-gap-x-2.5 tw-justify-start tw-px-4 tw-pb-2 hover:tw-bg-GRAY_1 tw-transition tw-ease-in-out tw-items-center'
            role='button'
            tabIndex={0}
            onClick={handleLogout}
          >
            <SvgSpriteLoader
              id='log-out-02'
              iconCategory={ICON_SPRITE_TYPES.GENERAL}
              width={14}
              height={14}
              color={'#666'}
            />
            <div className='tw-text-sm tw-text-[#666]'>Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTopbar;
