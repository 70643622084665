import { DragEventHandler, useRef, useState } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { toast } from 'utils/toast';

interface UploadProps {
  selectedFileNames?: string[];
  onChange: (file: FileList | null, index: number) => void;
  acceptedFormats?: string;
  clearable?: boolean;
  className?: string;
  isLoading?: boolean;
  error?: string | null;
  uploadProgress?: number | null;
  footer?: string;
  tabIndex?: number;
  iconColor?: string;
  filenameClassName?: string;
  fileCount?: number;
}

const FileUploaderV3: React.FC<UploadProps> = ({
  selectedFileNames = [],
  onChange,
  acceptedFormats,
  clearable = true,
  className,
  isLoading,
  error,
  uploadProgress,
  tabIndex = 0,
  iconColor = COLORS.ZAMP_PRIMARY,
  filenameClassName = 'tw-text-ZAMP_PRIMARY',
  fileCount = Infinity,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileDrop: DragEventHandler<HTMLDivElement> = (event) => {
    if (isLoading) return null;

    event?.preventDefault();
    event?.stopPropagation();
    setIsDragOver(false);

    const files = event?.dataTransfer?.files;

    if (!files) return;

    if (selectedFileNames.length + files.length > fileCount) {
      toast.error(`You can only upload up to ${fileCount} files.`);

      return;
    }

    onChange(files, 0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;

    if (!files) return;

    if (selectedFileNames?.length + files?.length > fileCount) {
      toast.error(`You can only upload up to ${fileCount} files.`);

      return;
    }

    onChange(files, 0);
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleClick();
  };

  const handleClear = (index: number, e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    setIsDragOver(false);
    onChange(null, index);
  };

  const renderContent = () => {
    return (
      <>
        {selectedFileNames?.map((selectedFileName, index) => (
          <div
            className='tw-min-w-[292px] tw-p-2 tw-bg-white tw-border tw-border-BASE_PRIMARY tw-flex tw-mb-3 tw-w-full'
            key={selectedFileName}
          >
            <div className={`tw-flex  tw-w-full tw-justify-between tw-items-center ${filenameClassName}`}>
              <div className='tw-flex tw-items-center tw-w-full'>
                <SvgSpriteLoader
                  className='tw-mr-3'
                  id='file-attachment-02'
                  height={16}
                  width={16}
                  iconCategory={ICON_SPRITE_TYPES.FILES}
                  color={iconColor}
                />
                <p className='tw-break-all tw-truncate md:tw-w-64 tw-w-52 tw-text-left'>{selectedFileName}</p>
              </div>

              {clearable && (
                <SvgSpriteLoader
                  className='tw-mr-1'
                  id='x-close'
                  height={12}
                  width={12}
                  onClick={(e) => handleClear(index, e)}
                  iconCategory={ICON_SPRITE_TYPES.GENERAL}
                />
              )}
            </div>
          </div>
        ))}
        {isLoading && uploadProgress !== null ? (
          <div className='tw-min-w-[292px] tw-text-center tw-p-2 tw-bg-white tw-border tw-border-BASE_PRIMARY tw-flex tw-mb-3 tw-w-full'>
            Uploaded {uploadProgress}%
          </div>
        ) : null}

        {!!error && (
          <SvgSpriteLoader
            className='tw-mb-4'
            color={COLORS.ERROR_400}
            id='alert-circle'
            height={40}
            width={40}
            iconCategory={ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK}
          />
        )}
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <SvgSpriteLoader id='file-plus-03' iconCategory={ICON_SPRITE_TYPES.FILES} />
          <div className='f-12-300 tw-whitespace-nowrap'>
            Drag & drop files here <span className='tw-text-TEXT_SECONDARY'>or</span>{' '}
            <span className='tw-underline'>Click to upload</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        tabIndex={tabIndex}
        className={`tw-relative tw-outline-0 focus:tw-border-ZAMP_PRIMARY focus:tw-border-solid tw-outline-0.5 tw-min-h-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-border-dashed tw-border tw-p-5 tw-border-DIVIDER_GRAY tw-rounded-2.5 ${
          isLoading ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
        }  ${className}  ${isDragOver ? 'tw-bg-BASE_PRIMARY' : 'tw-bg-BASE_SECONDARY'}`}
        onDrop={handleFileDrop}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onDragOver={(event) => event.preventDefault()}
        onDragEnter={() => setIsDragOver(true)}
        onDragExit={() => setIsDragOver(false)}
        onDragLeave={() => setIsDragOver(false)}
      >
        <div
          onDragOver={() => setIsDragOver(true)}
          className='tw-flex tw-flex-col tw-items-center tw-text-center tw-w-full'
        >
          {renderContent()}
        </div>
        <input
          type='file'
          ref={inputRef}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept={acceptedFormats}
          multiple
        />
      </div>
      {!!error && <div className={`tw-text-ERROR_RED tw-text-xs tw-mt-2`}>{error}</div>}
    </>
  );
};

export default FileUploaderV3;
