import { ASSET_PREFIX } from 'constants/config';

const IMAGE_PREFIX = ASSET_PREFIX ? `${ASSET_PREFIX}/public` : '';
const CDN_ASSET_PREFIX = 'https://storage.googleapis.com/zamp-prd-sg-payments-sdk/v1/public/images';

export const ZAMP_LOGO_PNG = IMAGE_PREFIX + '/icons/zamp_logo.png';
export const ZAMP_LOGO_WHITE = IMAGE_PREFIX + '/icons/zampWhite.svg';
export const ROMA_LOGO = IMAGE_PREFIX + '/images/roma-logo.svg';
export const ROMA_LOGO_WHITE = IMAGE_PREFIX + '/images/roma-logo-white.svg';
export const ROMA_LOGO_GREEN = IMAGE_PREFIX + '/images/roma-logo-green.svg';
export const FAVICON = IMAGE_PREFIX + '/icons/favicon.png';
export const FAVICON_ROMA = IMAGE_PREFIX + '/icons/favicon-roma.png';
export const HOME_ICON = IMAGE_PREFIX + '/icons/home_24x24.png';
export const ROMA_ACCOUNTS = IMAGE_PREFIX + '/icons/accounts.svg';
export const ACCOUNTS_ICON = IMAGE_PREFIX + '/icons/accounts_24x24.png';
export const CARDS_ICON = IMAGE_PREFIX + '/icons/cards_24x24.png';
export const PAYMENTS_ICON = IMAGE_PREFIX + '/icons/payments_24x24.png';
export const TRANSACTIONS_ICON = IMAGE_PREFIX + '/icons/transactions_24x24.png';
export const YIELD_ICON = IMAGE_PREFIX + '/icons/yield_24x24.png';
export const USER_PLACEHOLDER = IMAGE_PREFIX + '/icons/user_placeholder.png';
export const HOME_LINE = IMAGE_PREFIX + '/icons/home-line.png';
export const NO_INVESTMENT_IMAGE = IMAGE_PREFIX + '/images/no_investment_image.png';
export const COINS_HAND = IMAGE_PREFIX + '/icons/coins-hand.png';
export const BRIEFCASE = IMAGE_PREFIX + '/icons/breafcase-icon.svg';
export const DOWN_ARROW = IMAGE_PREFIX + '/icons/down_arrow.png';
export const RIGHT_ARROW = IMAGE_PREFIX + '/icons/right_arrow.png';
export const LEFT_POINTER = IMAGE_PREFIX + '/icons/left_pointer.png';
export const MOON_ICON = IMAGE_PREFIX + '/icons/moon.png';
export const HALF_SUN_ICON = IMAGE_PREFIX + '/icons/half_sun.png';
export const SUN_ICON = IMAGE_PREFIX + '/icons/sun.png';
export const INVEST_ICON = IMAGE_PREFIX + '/icons/invest_icon.png';
export const BELL_ICON = IMAGE_PREFIX + '/icons/bell_icon.png';
export const BELL_ICON_WHITE = IMAGE_PREFIX + '/icons/bell-white.svg';
export const LOGIN_IMG = IMAGE_PREFIX + '/images/login-bg.png';
export const USDC_COIN = IMAGE_PREFIX + '/images/usdc_coin.png';
export const ZAMP_LOGO = IMAGE_PREFIX + '/icons/zampBlack.svg';
export const EXTERNAL_LINK = IMAGE_PREFIX + '/icons/external_link.png';
export const IMAGE_PLACEHOLDER = IMAGE_PREFIX + '/icons/image_placeholder.png';
export const LOGIN_BG = IMAGE_PREFIX + '/images/orbit-background.png';
export const DROPDOWN_ICON = IMAGE_PREFIX + '/icons/dropdown-icon.svg';
export const UP_ARROW = IMAGE_PREFIX + '/icons/up-arrow.svg';
export const SIDEBAR_IMG = IMAGE_PREFIX + '/images/sidebar-img.png';
export const TICK = IMAGE_PREFIX + '/icons/complete-icon.svg';
export const CLOSE = IMAGE_PREFIX + '/icons/close-icon.svg';
export const PDF = IMAGE_PREFIX + '/icons/pdf-icon.svg';
export const ADD_FORM_FIELD = IMAGE_PREFIX + '/icons/add-icon.svg';
export const SUCCESS = IMAGE_PREFIX + '/icons/green-tick.svg';
export const ATTENTION = IMAGE_PREFIX + '/icons/attention-icon.svg';
export const LOGOUT_ICON = IMAGE_PREFIX + '/icons/logout_icon.png';
export const LOGOUT_ICON_BLACK = IMAGE_PREFIX + '/icons/logout_icon_black.png';
export const LOGOUT_ICON_2 = IMAGE_PREFIX + '/icons/logout_icon_2.png';
export const SETTINGS_ICON = IMAGE_PREFIX + '/icons/settings_icon.png';
export const TEAM_ICON = IMAGE_PREFIX + '/icons/team_icon.png';
export const LOADER_ICON = IMAGE_PREFIX + '/icons/loader.svg';
export const INFO_SQUARE_ICON = IMAGE_PREFIX + '/icons/info_square.png';
export const SEARCH_ICON = IMAGE_PREFIX + '/icons/search_icon.svg';
export const BLUE_TICK = IMAGE_PREFIX + '/icons/blue-tick.svg';
export const MOVE_MONEY = IMAGE_PREFIX + '/icons/move-money.svg';
export const DROPDOWN_BLUE = IMAGE_PREFIX + '/icons/dropdown-blue.svg';
export const NETWORK_ICON = IMAGE_PREFIX + '/icons/network-icon.svg';
export const LIST_PLACEHOLDER = IMAGE_PREFIX + '/icons/placeholder.svg';
export const COPY = IMAGE_PREFIX + '/icons/copy-icon.svg';
export const COPY_V2 = IMAGE_PREFIX + '/icons/copy-v2-icon.svg';
export const MOVE = IMAGE_PREFIX + '/icons/move.svg';
export const SWIPE_RIGHT = IMAGE_PREFIX + '/icons/swipe-right.svg';
export const RIGHT_BLACK = IMAGE_PREFIX + '/icons/right-arrow-black.svg';
export const SEND = IMAGE_PREFIX + '/icons/send.svg';
export const WALLET_HOME = IMAGE_PREFIX + '/icons/wallet-home.svg';
export const MINUS = IMAGE_PREFIX + '/icons/minus-icon.svg';
export const HOUR_GLASS_ICON = IMAGE_PREFIX + '/icons/hour-glass-icon.png';
export const DEPOSIT = IMAGE_PREFIX + '/icons/deposit-icon.svg';
export const NOTES = IMAGE_PREFIX + '/icons/notes.svg';
export const USDC = IMAGE_PREFIX + '/icons/usd.svg';
export const LINK = IMAGE_PREFIX + '/icons/link.svg';
export const ZERO_BALANCE_FOOTER_BACKGROUND = IMAGE_PREFIX + '/images/zero-balance-footer-background.png';
export const LINK_BLUE = IMAGE_PREFIX + '/icons/link-blue.svg';
export const CHEVRON_RIGHT = IMAGE_PREFIX + '/icons/chevronRight.svg';
export const CHEVRON_RIGHT_BLACK = IMAGE_PREFIX + '/icons/chevron-right-black.svg';
export const CHEVRON_RIGHT_WHITE = IMAGE_PREFIX + '/icons/chevron-right-white.png';
export const CHEVRON_LEFT_BLACK = IMAGE_PREFIX + '/icons/chevron-left.svg';
export const VIEW_EYE_BLACK = IMAGE_PREFIX + '/icons/view_eye_black.svg';
export const HIDE_EYE_BLACK = IMAGE_PREFIX + '/icons/hide_eye_black.svg';
export const MENU = IMAGE_PREFIX + '/icons/menu.svg';
export const DISABLED_ACCOUNT = IMAGE_PREFIX + '/icons/disable-account.svg';
export const GREEN_SINGLE_TICK = IMAGE_PREFIX + '/icons/green-single-tick.svg';
export const WHITE_TICK = IMAGE_PREFIX + '/icons/white-tick-with-circle.svg';
export const ACCELERATES_GROWTH = IMAGE_PREFIX + '/icons/accelerate-growth-icon.svg';
export const FLEXIBILITY = IMAGE_PREFIX + '/icons/flexibility-icon.svg';
export const HELP = IMAGE_PREFIX + '/icons/help-circle.svg';
export const ACC_PLUS = IMAGE_PREFIX + '/icons/plus.svg';
export const ACC_MINUS = IMAGE_PREFIX + '/icons/minus.svg';
export const TRANSACTION_ID = IMAGE_PREFIX + '/icons/transaction-id.svg';
export const DEPOSITE_INITIATED = IMAGE_PREFIX + '/icons/deposite-initiated.svg';
export const PROCESS_GREEN = IMAGE_PREFIX + '/icons/process-green.svg';
export const FIXED_INCOME_PORTFOLIO = IMAGE_PREFIX + '/images/fixed-income-portfolio.svg';
export const US_TREASURY = IMAGE_PREFIX + '/images/us-treasury.svg';
export const PLUS_WHITE = IMAGE_PREFIX + '/icons/plus-white.svg';
export const PLUS_BLACK = IMAGE_PREFIX + '/icons/plus-black.svg';
export const DELETE_GRAY = IMAGE_PREFIX + '/icons/delete-gray.svg';
export const DELETE_BLACK = IMAGE_PREFIX + '/icons/delete-black.svg';
export const IN_PROGRESS_IMAGE = IMAGE_PREFIX + '/images/in_progress.svg';
export const in_IMAGE = IMAGE_PREFIX + '/images/error.svg';
export const ERROR = IMAGE_PREFIX + '/icons/error.svg';
export const ERROR_IMAGE = IMAGE_PREFIX + '/images/error.svg';
export const CHECK_WHITE = IMAGE_PREFIX + '/icons/check-white.svg';
export const COPY_WHITE = IMAGE_PREFIX + '/icons/copy-white.svg';
export const GREEN_CLOCK_CHECK = IMAGE_PREFIX + '/icons/green-clock-check.svg';
export const GRAY_CLOCK_Z = IMAGE_PREFIX + '/icons/gray-clock-z.svg';
export const UP_DOWN_BLUE_ARROW = IMAGE_PREFIX + '/icons/up-down-blue-arrow.svg';
export const ERROR_WITH_BORDER = IMAGE_PREFIX + '/icons/error-with-border.svg';
export const INACTIVE_SORT = IMAGE_PREFIX + '/icons/inactive-sort.svg';
export const ACTIVE_SORT = IMAGE_PREFIX + '/icons/active-sort.svg';
export const BANK_COLORED = IMAGE_PREFIX + '/images/bank-colored.svg';
export const LINK_ACCOUNT = IMAGE_PREFIX + '/images/link-account.svg';
export const TRANSFER_ICON = IMAGE_PREFIX + '/icons/transfor-icon.svg';
export const WITHDRAW_FUNDS = IMAGE_PREFIX + '/images/withdraw-funds.svg';
export const INFO_ICON = IMAGE_PREFIX + '/icons/info-icon.svg';
export const EMAIL_OPEN = IMAGE_PREFIX + '/icons/open-email.svg';
export const HOME_WITH_DOOR = IMAGE_PREFIX + '/icons/home-with-door.svg';
export const PHONE_CALL = IMAGE_PREFIX + '/icons/phone-call.svg';
export const PERSON_WITH_TICK = IMAGE_PREFIX + '/icons/person-with-tick.svg';
export const CRYPTO_ICON = IMAGE_PREFIX + '/icons/crypto.svg';
export const BULK_TRANSACTIONS = IMAGE_PREFIX + '/icons/bulk-transactions.svg';
export const ARROW = IMAGE_PREFIX + '/icons/arrow.svg';
export const BULK_TRANSFER_ARROW = IMAGE_PREFIX + '/icons/bulk-transfer-arrow.svg';
export const FILTERS = IMAGE_PREFIX + '/icons/filters.svg';

//images
export const ISSUE_SUBMITTED = IMAGE_PREFIX + '/images/issue-submitted.svg';
export const NO_DATA_CLIPBOARD = IMAGE_PREFIX + '/images/no-data-clipboard.svg';
export const PRICING_BOTTOM_IMAGE = IMAGE_PREFIX + '/images/pricing-bottom-image.svg';
export const RED_INFO_BACKGROUND = IMAGE_PREFIX + '/images/banners/red_info.svg';

// Sidebar
export const SETTINGS_V2 = IMAGE_PREFIX + '/icons/settings_icon.png';
export const TEAMS = IMAGE_PREFIX + '/icons/teams.svg';
export const NAVBAR_SHADOW = IMAGE_PREFIX + '/images/navbar-shadow.svg';

//images
export const NO_DATA_CLIPBOARD_v2 = IMAGE_PREFIX + '/images/no-data-clipboard-v2.svg';

// KYB
export const KYB_STEP2 = IMAGE_PREFIX + '/icons/kyb/kyb-step-1.png';
export const KYB_STEP1 = IMAGE_PREFIX + '/icons/kyb/kyb-step-2.png';
export const KYB_STEP4 = IMAGE_PREFIX + '/icons/kyb/kyb-step-3.png';
export const KYB_STEP3 = IMAGE_PREFIX + '/icons/kyb/kyb-step-4.png';
export const HELP_ICON = IMAGE_PREFIX + '/icons/help_icon.svg';

// BENEFICIARY
export const ROMA_HOME = IMAGE_PREFIX + '/icons/roma-home.svg';
export const ROMA_BENEFICIARY = IMAGE_PREFIX + '/icons/roma-beneficiaries.svg';
export const ROMA_TRANSACTIONS = IMAGE_PREFIX + '/icons/roma-transactions.svg';
export const BANK = IMAGE_PREFIX + '/icons/bank-acc1.svg';
export const BANK_ACCOUNT = IMAGE_PREFIX + '/icons/bank.svg';
export const ADD_WHITE = IMAGE_PREFIX + '/icons/add-white.svg';
export const ETHEREUM = IMAGE_PREFIX + '/icons/eth.svg';
export const SOLANA = IMAGE_PREFIX + '/icons/solana.svg';
export const WALLET = IMAGE_PREFIX + '/icons/wallet.svg';
export const OPTIONS = IMAGE_PREFIX + '/icons/options.svg';
export const CRYPTO_WALLET = IMAGE_PREFIX + '/icons/crypto-wallet.svg';
export const GREEN_TICK = IMAGE_PREFIX + '/icons/tick.svg';
export const CROSS = IMAGE_PREFIX + '/icons/cross.svg';
export const CROSS_RED = IMAGE_PREFIX + '/icons/x-circle.svg';
export const CROSS_BLUE = IMAGE_PREFIX + '/icons/cross-blue.svg';
export const CROSS_GRAY = IMAGE_PREFIX + '/icons/cross-gray.svg';
export const SELECTED_GREEN = IMAGE_PREFIX + '/icons/selected.svg';
export const RED_CROSS = IMAGE_PREFIX + '/icons/redCross.svg';

// single-tansfer
export const ADD_BLACK = IMAGE_PREFIX + '/icons/add-black.svg';
export const PAYMENT_INITIATED = IMAGE_PREFIX + '/icons/payment-initiated.svg';
export const ETHERIUM = IMAGE_PREFIX + '/icons/eth.svg';
export const VIEW = IMAGE_PREFIX + '/icons/view.svg';
export const RIGHT = IMAGE_PREFIX + '/icons/right-arrow.svg';
export const PAYMENT_FAILED = IMAGE_PREFIX + '/icons/payment-failed.svg';
export const PAYMENT_FAILED_V2 = IMAGE_PREFIX + '/icons/payment-failed-v2.svg';
export const EQUALS_BLUE = IMAGE_PREFIX + '/icons/equals-blue.svg';
export const MINUS_BLUE = IMAGE_PREFIX + '/icons/minus-blue.svg';
export const SWITCH_BLUE = IMAGE_PREFIX + '/icons/switch-blue.svg';
export const INFO_ROUND = IMAGE_PREFIX + '/icons/info-round.svg';
export const DIVIDER = IMAGE_PREFIX + '/icons/divider.svg';

//yield
export const YIELD_HOME = IMAGE_PREFIX + '/icons/yield-home.svg';
export const YIELD_FUNDS = IMAGE_PREFIX + '/icons/yield-funds.svg';
export const YIELD_ACCOUNTS = IMAGE_PREFIX + '/icons/yield-accounts.svg';
export const YIELD_TRANSACTIONS = IMAGE_PREFIX + '/icons/yield-transactions.svg';
export const YIELD_REPORTS = IMAGE_PREFIX + '/icons/yield-reports.svg';
export const GROWTH_ICON = IMAGE_PREFIX + '/icons/growth-icon.svg';
export const BALANCED_ICON = IMAGE_PREFIX + '/icons/balanced-icon.svg';
export const INVEST_SIDEBAR = IMAGE_PREFIX + '/icons/invest-sidebar.svg';
export const GREEN_TICK_ROUND = IMAGE_PREFIX + '/icons/green-tick-round.svg';
export const RIGHT_ARROW_WHITE = IMAGE_PREFIX + '/icons/right-arrow-long-white.svg';
export const ZAMP_ICON = IMAGE_PREFIX + '/icons/zamp_icon.svg';
export const TREASURY_ACCOUNT = IMAGE_PREFIX + '/images/treasury-account.png';
export const GRADIENT_DOLLAR = IMAGE_PREFIX + '/icons/gradient-dollar.svg';
export const GRADIENT_LOCKER = IMAGE_PREFIX + '/icons/gradient-locker.svg';
export const EXTERNAL_LINK_ICON = IMAGE_PREFIX + '/icons/external-link.svg';
export const NO_TRANSACTIONS_IMAGE = IMAGE_PREFIX + '/images/no-transactions-image.svg';
export const BANK_GRAY = IMAGE_PREFIX + '/icons/bank_gray.png';
export const GRAPH_ARROW = IMAGE_PREFIX + '/images/graph-arrow.svg';
export const EDIT_BLUE = IMAGE_PREFIX + '/icons/edit-blue.svg';
export const PORTFOLIO_UP = IMAGE_PREFIX + '/icons/portfolio-up.svg';
export const PORTFOLIO_DOWN = IMAGE_PREFIX + '/icons/portfolio-down.svg';
export const CUSTOM_PORTFOLIO_SIDEBAR_IMG = IMAGE_PREFIX + '/images/custom-porfolio-sidbar-img.svg';
export const DIAMOND = IMAGE_PREFIX + '/icons/diamond.svg';
export const YEILD_ICON = IMAGE_PREFIX + '/icons/yeild-icon.svg';
export const ASSET_TYPE = IMAGE_PREFIX + '/icons/asset-type-icon.svg';
export const SORT_ICON = IMAGE_PREFIX + '/icons/sort-icon.svg';
export const SORT_ICON_ACTIVE = IMAGE_PREFIX + '/icons/sort-icon-active.svg';
export const BOOKMARK = IMAGE_PREFIX + '/icons/bookmark.svg';
export const BOOKMAR_SELECTED = IMAGE_PREFIX + '/icons/bookmark-selected.svg';

export const QUESTIONAIRE_SIDEBAR_IMAGE = IMAGE_PREFIX + '/images/questionaire-sidebar-image.png';
export const ADD_FUNDS_SIDEBAR_IMAGE = IMAGE_PREFIX + '/images/add-funds.svg';
export const ADD_FUNDS_ICON = IMAGE_PREFIX + '/icons/add-funds.svg';
export const DOLLAR_WITH_CIRCLE = IMAGE_PREFIX + '/icons/dollar-with-circler.svg';
export const CALANDER_WITH_TICK = IMAGE_PREFIX + '/icons/calander-with-tick.svg';
export const YIELD = IMAGE_PREFIX + '/icons/yield.svg';
export const CLIPBOARD = IMAGE_PREFIX + '/icons/clipboard.svg';
export const BLUE_FILE_ICON = IMAGE_PREFIX + '/icons/files-icon-blue.svg';
export const COMPANY_DETAILS_ICON = IMAGE_PREFIX + '/icons/company-details-icon.svg';

//mfa

export const ALERT = IMAGE_PREFIX + '/images/mfa/alert.svg';
export const NEXT = IMAGE_PREFIX + '/images/mfa/next.svg';
export const PLUS = IMAGE_PREFIX + '/images/mfa/plus.svg';
export const SCAN = IMAGE_PREFIX + '/images/mfa/scan.svg';
export const SECURITY = IMAGE_PREFIX + '/images/mfa/security.svg';
export const SUCCESS_TICK = IMAGE_PREFIX + '/images/mfa/tick.svg';
export const VERIFIED = IMAGE_PREFIX + '/images/mfa/verified.svg';
export const ESCAPE = IMAGE_PREFIX + '/images/mfa/escape.svg';
export const WAVE_DIVIDER = IMAGE_PREFIX + '/images/mfa/wave-divider.svg';
export const ENABLE_MFA = IMAGE_PREFIX + '/images/mfa/enable.svg';

export const SCREEN_SUPPORT = IMAGE_PREFIX + '/images/screen-support.svg';
//bulk-payout
export const ERROR_FILE = IMAGE_PREFIX + '/icons/folder-x.svg';
export const ANNOUNCEMENT = IMAGE_PREFIX + '/icons/announcement-02.svg';
export const MULTI_USERS = IMAGE_PREFIX + '/icons/users-02.svg';
export const UPLOAD_CLOUD = IMAGE_PREFIX + '/icons/upload-cloud-01.svg';
export const DOWNLOAD_FILE = IMAGE_PREFIX + '/icons/file-download-03.svg';
export const DOWNLOAD_ICON = IMAGE_PREFIX + '/icons/download-02.svg';
export const CHECK_VERIFIED = IMAGE_PREFIX + '/icons/check-verified-02.svg';
export const DOWNLOAD_ICON_WITH_BASE = IMAGE_PREFIX + '/icons/download-icon-with-base.svg';

//Teams

export const TRASH = IMAGE_PREFIX + '/icons/trash.svg';
export const EDIT = IMAGE_PREFIX + '/icons/edit.svg';

// Approve and Pay
export const APPROVE = IMAGE_PREFIX + '/icons/approve.svg';
export const APPROVAL_PENDING = IMAGE_PREFIX + '/icons/approval-pending.svg';
export const BULK_TRANFER = IMAGE_PREFIX + '/icons/transfer-out.svg';

export const FIAT_TRANSFER = IMAGE_PREFIX + '/icons/fiat-transfer.svg';

// Payment Requests
export const ARROW_DOWN_LEFT = IMAGE_PREFIX + '/icons/arrow-down-left.svg';
export const SUCCESS_EMAIL = IMAGE_PREFIX + '/icons/success.svg';
export const ARROW_DOWN_LEFT_WHITE = IMAGE_PREFIX + '/icons/arrow-down-left-white.svg';

//Cards
export const SIDEBAR_CARD = IMAGE_PREFIX + '/icons/cards/sidebar-card.svg';
export const CARD_SKELETON = IMAGE_PREFIX + '/images/cards/card-skeleton.svg';
export const CARD_PLACEHOLDER = IMAGE_PREFIX + '/images/cards/card-placeholder.svg';
export const CARD_INFO = IMAGE_PREFIX + '/images/cards/card-info.svg';
export const CARD_INFO_FROZEN = IMAGE_PREFIX + '/images/cards/card-info-frozen.svg';
export const CARD_PLUS = IMAGE_PREFIX + '/icons/cards/credit-card-plus.svg';
export const CARD_BANNER = IMAGE_PREFIX + '/images/cards/banner-cards.svg';
export const FREEZE_CARD = IMAGE_PREFIX + '/icons/cards/freeze-card.svg';
export const UNFREEZE_CARD = IMAGE_PREFIX + '/icons/cards/unfreeze-card.svg';
export const MANAGE_CARD = IMAGE_PREFIX + '/icons/cards/manage-card.svg';
export const VIEW_DETAILS = IMAGE_PREFIX + '/icons/cards/eye.svg';
export const CARD_FREEZE_WHITE = IMAGE_PREFIX + '/icons/cards/snowflake-white.svg';
export const CARD_UNFREEZE_WHITE = IMAGE_PREFIX + '/icons/cards/credit-card-refresh-white.svg';
export const CARD_CHEVRON_RIGHT = IMAGE_PREFIX + '/icons/cards/chevron-right.svg';
export const ARROW_UP_RIGHT = IMAGE_PREFIX + '/icons/cards/arrow-narrow-up-right.svg';
export const ARROW_RIGHT = IMAGE_PREFIX + '/icons/cards/arrow-narrow-right.svg';
export const INFO_CIRCLE = IMAGE_PREFIX + '/icons/cards/info-circle.svg';
export const CARD_COPY = IMAGE_PREFIX + '/icons/cards/copy.svg';
export const TICK_GRAY = IMAGE_PREFIX + '/icons/cards/tick-gray.svg';
export const ISSUE_CARD_SUCCESS = IMAGE_PREFIX + '/images/cards/issue-card-success.svg';
export const CARD_CHEVRON_RIGHT_DISABLED = IMAGE_PREFIX + '/icons/cards/chevron-right-disabled.svg';
export const CARD_CHEVRON_LEFT = IMAGE_PREFIX + '/icons/cards/chevron-left.svg';
export const CARD_FREEZE = IMAGE_PREFIX + '/icons/cards/snowflake.svg';
export const X_CLOSE = IMAGE_PREFIX + '/icons/cards/x-close.svg';
export const ADJUST_LIMIT_DEFAULT = IMAGE_PREFIX + '/icons/cards/settings.svg';
export const EDIT_NICKNAME_DEFAULT = IMAGE_PREFIX + '/icons/cards/credit-card-edit.svg';
export const DELETE_CARD_DEFAULT = IMAGE_PREFIX + '/icons/cards/credit-card-x.svg';
export const ADJUST_LIMIT_CLOSED = IMAGE_PREFIX + '/icons/cards/settings-disabled.svg';
export const EDIT_NICKNAME_CLOSED = IMAGE_PREFIX + '/icons/cards/credit-card-edit-disabled.svg';
export const DELETE_CARD_CLOSED = IMAGE_PREFIX + '/icons/cards/credit-card-x-disabled.svg';
export const CHECK_GREEN = IMAGE_PREFIX + '/icons/cards/check-green.svg';

export const LOGIN_LAYOUT_IMAGE = IMAGE_PREFIX + '/icons/signup_image.svg';

//currencies
export const SGD = IMAGE_PREFIX + '/icons/currencies/sgd.svg';
export const AED = IMAGE_PREFIX + '/icons/currencies/aed.svg';
export const EUR = IMAGE_PREFIX + '/icons/currencies/eur.svg';
export const INR = IMAGE_PREFIX + '/icons/currencies/inr.svg';
export const USD = IMAGE_PREFIX + '/icons/currencies/usd.svg';

export const MMF = IMAGE_PREFIX + '/images/money-market-fund.svg';

//zero data
export const PAYOUTS_ZERO_DATA = IMAGE_PREFIX + '/images/banners/payouts_zero_data.svg';
export const PAYOUTS_ZERO_DATA_IN_PROGRESS = IMAGE_PREFIX + '/images/banners/payouts_zero_data_in_progress.svg';

export const CHEVRON_DOWN = IMAGE_PREFIX + '/icons/chevron-down.svg';

export const BANK_NOTE = IMAGE_PREFIX + '/icons/bank-note-01.svg';
export const INFO_WHITE_CIRCLE = IMAGE_PREFIX + '/icons/info-white-circle.svg';

export const VAULT = IMAGE_PREFIX + '/icons/vault.svg';

export const SEARCH_MD = IMAGE_PREFIX + '/icons/search-md.svg';
export const SAFE = IMAGE_PREFIX + '/icons/safe.svg';
export const CHEVRON_RIGHT_DESTINY_BLACK = IMAGE_PREFIX + '/icons/chevron-right.svg';
export const CHEVRON_DOWN_DESTINY_BLACK = IMAGE_PREFIX + '/icons/chevron-down-destiny-black.svg';
export const PLUS_CIRCLE = IMAGE_PREFIX + '/icons/plus-circle.svg';
export const CHECK = IMAGE_PREFIX + '/icons/check.svg';
export const ADD_GRAY = IMAGE_PREFIX + '/icons/add-gray.svg';
export const ALL_TRANSACTIONS = IMAGE_PREFIX + '/images/all_transactions.svg';
export const BANK_TRANSFER = IMAGE_PREFIX + '/images/bank_transfer.svg';
export const TREASURY_TRANSFER = IMAGE_PREFIX + '/images/treasury_transfer.svg';

export const INTEGRATIONS_ZERO_STATE = IMAGE_PREFIX + '/images/integrations-zero-state.svg';

export const PROMPT_TREASURY_SAFETY = IMAGE_PREFIX + '/images/prompt-treasury-safety.svg';
export const PROMPT_TREASURY_LIQUIDITY = IMAGE_PREFIX + '/images/prompt-treasury-liquidity.svg';
export const PROMPT_TREASURY_TRUST = IMAGE_PREFIX + '/images/prompt-treasury-trust.svg';

export const BROKERAGE_ACCOUNT_LOGO = IMAGE_PREFIX + '/images/brokerage.svg';
export const CROSS_SELL_CARDS = IMAGE_PREFIX + '/images/cross-sell-cards.svg';
export const CROSS_SELL_BANKING = IMAGE_PREFIX + '/images/cross-sell-banking.svg';
export const CROSS_SELL_TREASURY = IMAGE_PREFIX + '/images/cross-sell-treasury.svg';
export const BULK_TRANSFER = IMAGE_PREFIX + '/images/bulk_transfer.svg';
export const KYB_PENDING = IMAGE_PREFIX + '/images/kyb/pending.svg';
export const KYB_SUCCESS = IMAGE_PREFIX + '/images/kyb/success.svg';
export const KYB_FAILURE = IMAGE_PREFIX + '/images/kyb/failed.svg';

export const BULK_TRANSACTIONS_V2 = IMAGE_PREFIX + '/icons/bulk-transactions-v2.svg';

export const NO_PERMISSIONS = IMAGE_PREFIX + '/images/no-permission.svg';

export const OKTA_LOGO = IMAGE_PREFIX + '/images/okta-logo.svg';
export const GOOGLE_LOGO = IMAGE_PREFIX + '/images/google-logo.svg';

// intent
export const USD_BUSINESS_BANKING = IMAGE_PREFIX + '/icons/intent/usd-business-banking.svg';
export const ON_OFF_RAMP = IMAGE_PREFIX + '/icons/intent/on-off-ramp.svg';
export const CRYPTO_PAYMENT_GATEWAY = IMAGE_PREFIX + '/icons/intent/crypto-payment-gateway.svg';

// otc
export const OTC_QUOTE_ZERO_DATA = IMAGE_PREFIX + '/images/bitcoin-dollar.svg';
export const HALF_CIRCLE_LINE_ARROW = IMAGE_PREFIX + '/icons/otc/half-circle-line-arrow.svg';

//retail dashboard
export const LOGIN_LAYOUT_MOBILE = IMAGE_PREFIX + '/images/retail-dashboard/login-layout-mobile.svg';
export const RFI_REVIEW_STATUS = IMAGE_PREFIX + '/images/retail-dashboard/desktop-review-status.svg';
export const RFI_REVIEW_MOBILE_STATUS = IMAGE_PREFIX + '/images/retail-dashboard/mobile-review-status.svg';
export const RFI_FAILED_STATUS = IMAGE_PREFIX + '/images/retail-dashboard/desktop-failed-status.svg';
export const RFI_FAILED_MOBILE_STATUS = IMAGE_PREFIX + '/images/retail-dashboard/mobile-failed-status.svg';
export const MAIN_TRANSACTION_PAGE = CDN_ASSET_PREFIX + '/main-transaction-page.svg';
export const MAIN_TRANSACTION_PAGE_MOBILE = CDN_ASSET_PREFIX + '/main-transaction-page-mobile.svg';
export const RFI_SUCCESS_STATUS = IMAGE_PREFIX + '/images/retail-dashboard/desktop-success-status.svg';
export const RFI_SUCCESS_MOBILE_STATUS = IMAGE_PREFIX + '/images/retail-dashboard/mobile-success-status.svg';
